import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { ColGroupDef, GridApi } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, LanguageService, PermissionsService, ToastService } from '@core/services';
import { AdminUsersService } from '@pages/admin-users';
import { IAdminUserPermission } from '@core/interfaces';
import { generateColumnDefs } from '@core/utilities';

@Component({
    selector: 'app-admin-user-permissions',
    templateUrl: './admin-user-permissions.component.html',
    styleUrls: ['./admin-user-permissions.component.scss']
})

export class AdminUserPermissionsComponent implements OnInit {

    public tableNameUserPermissions: string = 'user-permissions';
    public tableNameAdminUserPermissions: string = 'admin-user-permissions';
    public columnDefsUserPermissions: ColGroupDef[] = [
        {
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'UserPermissions'),
            children: [
                {
                    field: 'Id',
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    field: 'Name',
                    headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Name'),
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    minWidth: 100,
                },
                {
                    field: 'Description',
                    headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Description'),
                    sortable: true,
                    minWidth: 100,
                },
            ],
        },
    ];
    public columnDefsAdminUserPermissions: ColGroupDef[] = [
        {
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'MyPermissions'),
            children: [
                {
                    field: 'Id',
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    field: 'Name',
                    headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Name'),
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    minWidth: 100,
                },
                {
                    field: 'Description',
                    headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Description'),
                    sortable: true,
                    minWidth: 100,
                },
            ],
        }
    ];
    public rowSelection: 'single' | 'multiple' = 'multiple';

    private adminUserId: number;
    private userId: number;
    public userPermission: IAdminUserPermission[];
    public adminUserPermission: IAdminUserPermission[];
    public userPermissionSelectedRow: IAdminUserPermission[] = [];
    public adminUserPermissionSelectedRow: IAdminUserPermission[] = [];
    public isRowSelectable = userPermission => !userPermission.data.Disabled;
    public getRowClass = rowData => rowData.data.Disabled ? 'disabled-row' : '';


    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private adminUsersService: AdminUsersService,
        private toastService: ToastService,
        public permissionsService: PermissionsService,
    ) {
        this.columnDefsUserPermissions[0].children =
            generateColumnDefs(this.tableNameUserPermissions, this.columnDefsUserPermissions[0].children);
        this.columnDefsAdminUserPermissions[0].children =
            generateColumnDefs(this.tableNameAdminUserPermissions, this.columnDefsAdminUserPermissions[0].children);
    }

    ngOnInit(): void {
        this.adminUserId = this.authService.userData$.getValue().Id;
        this.userId = +this.route.parent.snapshot.params.id;
        this.getPermissions();
    }

    private getPermissions(): void {
        forkJoin([
            this.adminUsersService.getPermissions({ userId: this.adminUserId }),
            this.adminUsersService.getPermissions({ userId: this.userId })]
        )
            .pipe(take(1))
            .subscribe({
                next: (res: [IAdminUserPermission[], IAdminUserPermission[]]) => {
                    this.adminUserPermission =
                        this.adminUsersService.disableSelectableRows(res[0], res[1], 'Id', true);
                    this.userPermission = this.adminUsersService.disableSelectableRows(res[1], res[0], 'Id', false);
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                },
            });
    }

    public adminUserPermissionsChange($event: GridApi): void {
        this.adminUserPermissionSelectedRow = $event.getSelectedRows();
    }

    public userPermissionsChange($event: GridApi): void {
        this.userPermissionSelectedRow = $event.getSelectedRows();
    }

    public addPermissionsToUser(): void {
        const payload = {
            PermissionIds: this.adminUserPermissionSelectedRow.map(item => item.Id),
            UserId: this.userId,
        };
        this.adminUsersService.addPermissionToUser(payload)
            .pipe(take(1))
            .subscribe({
                next: (res: IAdminUserPermission[]) => {
                    this.userPermission =
                        this.adminUsersService.disableSelectableRows(res, this.adminUserPermission, 'Id', false);
                    this.adminUserPermission =
                        this.adminUsersService.disableSelectableRows(this.adminUserPermission, res, 'Id', true);
                    this.adminUserPermissionSelectedRow = [];
                    this.userPermissionSelectedRow = [];
                    this.toastService.showToastMsg('success', 'CreationSuccessMessage', 'Permission');
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });

    }

    public removePermissionsFromUser(): void {
        const payload = {
            PermissionIds: this.userPermissionSelectedRow.map(permission => permission.Id),
            UserId: this.userId,
        };
        this.adminUsersService.removePermissionFromUser(payload)
            .pipe(take(1))
            .subscribe({
                next: (res: IAdminUserPermission[]) => {
                    this.userPermission =
                        this.adminUsersService.disableSelectableRows(res, this.adminUserPermission, 'Id', false);
                    this.adminUserPermission =
                        this.adminUsersService.disableSelectableRows(this.adminUserPermission, res, 'Id', true);
                    this.adminUserPermissionSelectedRow = [];
                    this.userPermissionSelectedRow = [];
                    this.toastService.showToastMsg('success', 'RemoveSuccessMessage', 'Permission');
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });

    }

}
