import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { ToastModule } from 'primeng/toast';

import { AppComponent } from './app.component';
import { AppNotfoundComponent } from '@pages/notfound/app.notfound.component';
import { AppMaintenanceComponent } from '@pages/maintenance';

import { RadioButtonModule } from 'primeng/radiobutton';
import { environment } from '@env/environment';
import { AccessDeniedComponent } from '@pages/acces-denied';

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    // return new TranslateHttpLoader(httpClient, `assets/json/translations/`, '.json');
    return new TranslateHttpLoader(httpClient, `${environment.cdnUrl}agentFiles/0/json/translations/`, '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxMaskModule.forRoot(),
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CoreModule,
        RadioButtonModule,
        ToastModule,
    ],
    declarations: [
        AppComponent,
        AppNotfoundComponent,
        AppMaintenanceComponent,
        AccessDeniedComponent,
    ],
    providers: [
        // {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
