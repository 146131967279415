<div *ngIf="adminUserDetail"
     class="inner-page-main-section"
     [ngClass]="{'m-inner-page-main-section' : !isDesktopView}">
    <form [formGroup]="adminUserDetailForm">
        <div class="p-fluid p-formgrid grid form">
            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field editable-field">
                        <label class="field-label-name" for="FirstName">{{ 'FirstName' | translate }}</label>
                        <div class="input-error-section">
                            <input pInputText id="FirstName"
                                   type="text"
                                   formControlName="FirstName"
                                   [ngClass]="{'ng-touched ng-invalid' :
                                           (adminUserDetailForm.controls.FirstName.touched)
                                           && adminUserDetailForm.controls.FirstName.invalid}"
                            />
                            <small *ngIf="adminUserDetailForm.controls.FirstName.touched
                                    && adminUserDetailForm.controls.FirstName.hasError('maxlength')"
                                   class="p-error">
                                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                            </small>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="field editable-field">
                        <label class="field-label-name" for="LastName">{{ 'LastName' | translate }}</label>
                        <div class="input-error-section">
                            <input pInputText id="LastName"
                                   type="text"
                                   formControlName="LastName"
                                   [ngClass]="{'ng-touched ng-invalid' :
                                           adminUserDetailForm.controls.LastName.touched
                                            && adminUserDetailForm.controls.LastName.invalid}"/>
                            <small *ngIf="adminUserDetailForm.controls.LastName.touched
                                    && adminUserDetailForm.controls.LastName.hasError('maxlength')"
                                   class="p-error">
                                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                            </small>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="field editable-field">
                        <label class="field-label-name" for="PhoneNumber">
                            {{ 'PhoneNumber' | translate }}
                        </label>
                        <div class="input-error-section">
                            <input pInputText
                                   id="PhoneNumber"
                                   type="tel"
                                   formControlName="PhoneNumber"
                                   [ngClass]="{'ng-touched ng-invalid' :
                                           adminUserDetailForm.controls.PhoneNumber.touched
                                            && adminUserDetailForm.controls.PhoneNumber.invalid}"/>
                            <small *ngIf="adminUserDetailForm.controls.PhoneNumber.touched
                                    && adminUserDetailForm.controls.PhoneNumber.hasError('maxlength')"
                                   class="p-error">
                                {{ 'MaxLengthValidationMessage' | translate: {length: 50} }}
                            </small>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field editable-field">
                        <label class="field-label-name">{{ 'Status' | translate }}</label>
                        <app-status-view [statusDetail]="adminUserDetail.StatusDetail"
                                         [translationPrefix]="'EnumAdminUserState'">
                        </app-status-view>
                    </div>
                </div>
                <div>
                    <div class="field">
                        <div class="field-name">{{ 'RegistrationDate' | translate }}</div>
                        <div class="field-value">
                            {{ adminUserDetail.RegistrationDate ? (adminUserDetail.RegistrationDate
                            | date:'dd.MM.YYYY HH:mm:ss': adminUserDetail.TimeZone.toString()) : '-' }}
                        </div>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-label-name">{{ 'TimeZone' | translate }} </label>
                        <div class="input-error-section">
                            <app-time-zone-container
                                [timeZoneOffset]="adminUserDetailForm.controls.TimeZone.value"
                                (updateTimeZone)="onUpdateTimeZone($event)">
                            </app-time-zone-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
