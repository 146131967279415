import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, iif } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import {
    ColDef,
    GetContextMenuItemsParams,
    ICellRendererParams,
    IServerSideDatasource,
    MenuItemDef,
    ServerSideStoreType,
} from 'ag-grid-community';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';

import { CreateAdminUserComponent, AdminUsersService } from '@pages/admin-users';
import { AuthService, LanguageService, PermissionsService, ToastService } from '@core/services';
import {
    ActionsRendererComponent,
    ChangePasswordDialogComponent,
    ConfirmationDialogComponent,
    DateCellRenderer,
    LinkCellComponent,
    MemberStateRenderer
} from '@app/shared';
import { generateColumnDefs } from '@core/utilities';
import { IChangePassword, IAdminUser, IFilter, IResponseWithCount } from '@core/interfaces';
import { MemberStatesEnum } from '@core/enums';

@Component({
    selector: 'app-admin-users',
    templateUrl: './admin-users.component.html',
    styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

    public tableName: string = 'admin-users';
    public pagination: boolean = true;
    public paginationPageSize: number = 50;
    public columnDefs: ColDef[] = [
        {
            field: 'Id',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'ID'),
            cellRenderer: LinkCellComponent,
            cellRendererParams: {
                text: (params: ICellRendererParams) => `${params.data.Id}`,
                link: (params: ICellRendererParams) => {
                    if (params.data.Id === this.authService.userData$.getValue().Id) {
                        return '/my-profile';
                    }

                    return `${params.data.Id}`;
                }
            },
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            minWidth: 80,
        },
        {
            field: 'FirstName',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'FirstName'),
            headerTooltip: this.translateService.instant('FirstName'),
            filter: 'agTextColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            minWidth: 140,
        },
        {
            field: 'LastName',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'LastName'),
            headerTooltip: this.translateService.instant('LastName'),
            filter: 'agTextColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            minWidth: 140,
        },
        {
            field: 'UserName',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Username'),
            headerTooltip: this.translateService.instant('Username'),
            cellRenderer: MemberStateRenderer,
            cellRendererParams: {
                rendererKey: 'Status'
            },
            filter: 'agTextColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            sortable: true,
            minWidth: 140,
        },
        {
            field: 'Currency',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Currency'),
            headerTooltip: this.translateService.instant('Currency'),
            filter: 'agTextColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            minWidth: 80,
        },
        {
            field: 'PhoneNumber',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'PhoneNumber'),
            headerTooltip: this.translateService.instant('PhoneNumber'),
            filter: 'agTextColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            sortable: true,
            minWidth: 140,
        },
        {
            field: 'RegistrationDate',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'RegistrationDate'),
            headerTooltip: this.translateService.instant('RegistrationDate'),
            cellRenderer: DateCellRenderer,
            filter: 'agDateColumnFilter',
            filterParams: {
                alwaysShowBothConditions: true,
                defaultJoinOperator: 'OR',
            },
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'actions',
            headerName: ' ',
            cellRendererSelector: params => {
                return {
                    component: ActionsRendererComponent,
                    params: {
                        data: {
                            menuItems: [
                                {
                                    label: this.translateService.instant('Properties'),
                                    icon: 'pi pi-fw icon-info icon-size-sm',
                                    command: () => {
                                        if (params.data.Id === this.authService.userData$.getValue().Id) {
                                            this.router.navigate([`/my-profile`]);
                                        } else {
                                            this.router.navigate([`/users/${params.data.Id}`]);
                                        }
                                    }
                                },
                                ...(this.permissionsService
                                    .checkPermission(this.permissionsService.permissions?.AdminUserPermissions?.ChangeAdminUserStatus) ? [
                                    {
                                        separator: true
                                    },
                                    {
                                        label: params.data.Status === MemberStatesEnum.Active
                                            ? this.translateService.instant('Block')
                                            : this.translateService.instant('Unblock'),
                                        icon: params.data.Status === MemberStatesEnum.Active ? 'pi pi-fw icon-lock icon-size-sm'
                                            : 'pi pi-fw icon-unlock icon-size-sm',
                                        command: () => {
                                            this.openConfirmationDialog(params.data);
                                        }
                                    },
                                ] : []),
                                ...(this.permissionsService
                                    .checkPermission(this.permissionsService.permissions?.AdminUserPermissions?.ChangeAdminUserPassword) ? [
                                    {
                                        separator: true
                                    },
                                    {
                                        label: this.translateService.instant('ChangePassword'),
                                        icon: 'pi pi-fw icon-password icon-size-sm',
                                        command: () => {
                                            this.openChangePasswordDialog(params.data);
                                        }
                                    },
                                ] : [])
                            ],
                        }
                    }
                };
            },
            menuTabs: [],
            suppressColumnsToolPanel: true,
            resizable: false,
            filter: false,
            sortable: false,
            minWidth: 40,
            maxWidth: 40,
        },
    ];

    private filterObj: IFilter;
    public params: IServerSideGetRowsParams;
    public serverSideStoreType: ServerSideStoreType = 'partial';
    public rowModelType: string = 'serverSide';

    public dataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            this.params = params;
            params.api.hideOverlay();
            this.getAdminUsers(this.filterObj, params);
        }
    };

    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
        private adminUsersService: AdminUsersService,
        private toastService: ToastService,
        private dialogService: DialogService,
        private router: Router,
        private authService: AuthService,
        public permissionsService: PermissionsService,
    ) {
        this.columnDefs = generateColumnDefs(this.tableName, this.columnDefs);
    }

    ngOnInit(): void {
    }

    private getAdminUsers(filterObj: IFilter, params: IServerSideGetRowsParams): void {
        const payload: any = {
            filterModel: params.request.filterModel,
            sortModel: params.request.sortModel,
            TakeCount: this.paginationPageSize,
            SkipCount: params.request.endRow / this.paginationPageSize - 1,
            ...filterObj
        };
        this.adminUsersService.getAdminUsers(payload)
            .pipe(take(1))
            .subscribe({
                next: (res: IResponseWithCount<IAdminUser>) => {
                    params.success({
                        rowData: res.Entities,
                        rowCount: res.Count
                    });
                    params.columnApi.autoSizeAllColumns(true);
                    params.api.sizeColumnsToFit();
                    res.Count ? params.api.hideOverlay() : params.api.showNoRowsOverlay();
                },
                error: (err: HttpErrorResponse) => {
                    params.success({
                        rowData: [],
                        rowCount: 0,
                    });
                    params.api.showNoRowsOverlay();
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public openAddEditAdminUserDialog(): void {
        const ref = this.dialogService.open(CreateAdminUserComponent, {
            header: this.translateService.instant('AddUser'),
            width: '40%',
            styleClass: 'dialog-with-footer',
        });
        ref.onClose
            .pipe(take(1))
            .subscribe({
                next: result => {
                    if (result) {
                        this.getAdminUsers(this.filterObj, this.params);
                    }
                }
            });
    }

    private openConfirmationDialog(paramsData: any): void {
        const Status = paramsData.Status === MemberStatesEnum.Active ? MemberStatesEnum.Blocked : MemberStatesEnum.Active;
        const ref = this.dialogService.open(ConfirmationDialogComponent, {
            header: this.translateService.instant('Confirmation'),
            data: {
                text: paramsData.Status === MemberStatesEnum.Active
                    ? this.translateService.instant('BlockConfirmText')
                    : this.translateService.instant('UnblockConfirmText')
            },
            width: '40%',
            styleClass: 'dialog-with-footer'
        });
        ref.onClose
            .pipe(
                switchMap((isConfirmed: boolean) => {
                    const data = {
                        Id: paramsData.Id,
                        Status
                    };

                    return iif(() => isConfirmed, this.adminUsersService.changeStatus(data), EMPTY);
                }),
                take(1)
            )
            .subscribe(() => {
                paramsData.Status = Status;
                this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'User');
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    private openChangePasswordDialog(paramsData: IAdminUser): void {
        const ref = this.dialogService.open(ChangePasswordDialogComponent, {
            header: this.translateService.instant('ChangePassword'),
            width: '40%',
            styleClass: 'dialog-with-footer'
        });
        ref.onClose
            .pipe(
                switchMap((modalData: any) => {
                    const changePass: IChangePassword = {
                        Id: paramsData.Id,
                        ...modalData
                    };
                    return iif(() => modalData, this.adminUsersService.changePassword(changePass), EMPTY);
                }),
                take(1)
            )
            .subscribe(() => {
                this.toastService.showToastMsg(
                    'success',
                    this.translateService.instant('PasswordSuccessfullyChanged')
                );
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    public getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
        return params.node ? [
            {
                name: this.translateService.instant('Properties'),
                icon: '<i class="pi icon-info icon-size-sm"></i>',
                action: () => {
                    if (params.node.data.Id === this.authService.userData$.getValue().Id) {
                        this.router.navigate([`/my-profile`]);
                    } else {
                        this.router.navigate([`/users/${params.node.data.Id}`]);
                    }
                }
            },
            ...(this.permissionsService.checkPermission(this.permissionsService.permissions?.AdminUserPermissions?.ChangeAdminUserStatus) ? [
                {
                    name: params.node.data.Status === MemberStatesEnum.Active
                        ? this.translateService.instant('Block')
                        : this.translateService.instant('Unblock'),
                    icon: params.node.data.Status === MemberStatesEnum.Active
                        ? '<i class="pi icon-lock icon-size-sm"></i>'
                        : '<i class="pi icon-unlock icon-size-sm"></i>',
                    action: () => {
                        this.openConfirmationDialog(params.node.data);
                    },
                },
            ] : []),
            ...(this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AdminUserPermissions?.ChangeAdminUserPassword) ? [
                {
                    name: this.translateService.instant('ChangePassword'),
                    icon: '<i class="pi icon-password icon-size-sm"></i>',
                    action: () => {
                        this.openChangePasswordDialog(params.node.data);
                    },
                },
            ] : []),
        ] : [];
    }

}
