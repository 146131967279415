export enum Methods {
    MAIN_REQUEST = 'ApiRequest',
    LOGIN_USER = 'authentication/login',
    GET_CURRENT_AGENT = 'agent/getcurrentprofile',
    GET_CURRENT_USER = 'user/getcurrentprofile',
    PARTNER_TEMPLATE_CONFIG = 'authentication/getconfig',
    ADD_PERMISSION_TEMPLATE = 'permissiontemplate/add',
    UPDATE_PERMISSION_TEMPLATE = 'permissiontemplate/update',
    GET_PERMISSION_TEMPLATE_BY_PARTNER_ID = 'permissiontemplate/getbypartnerid',
    GET_PERMISSIONS_BY_AGENT_ID = 'permission/gettemplatesbyagentid',
    GET_PARTNERS = 'partner/getall',
    GET_PARTNER_ENUM = 'partner/getpartners',
    GET_PARTNER_BY_ID = 'partner/getbyid',
    GET_PARTNERS_STATES = 'partner/getstatuses',
    CREATE_PARTNER = 'partner/add',
    UPDATE_PARTNER = 'partner/update',
    GET_AGENT_TREE_BY_ID = 'agent/gettreebyid',
    GET_AGENT_TREE_BY_USER = 'agent/getagenttreebyuser',
    GET_AGENT_TRANSACTION_TREE = 'agent/gettransactiontree',
    GET_AGENT_TRANSACTION = 'agent/gettransaction',
    ADD_AGENT = 'agent/add',
    GET_AGENT_BY_ID = 'agent/getbyid',
    UPDATE_AGENT_DETAIL = 'agent/update',
    AGENT_CHANGE_PASSWORD = 'agent/changepassword',
    USER_CHANGE_PASSWORD = 'user/changeownpassword',
    CHANGE_STATUS = 'agent/changeagentststus',
    GET_CURRENCIES = 'currency/getall',
    GET_AGENT_STATUSES = 'agent/getstatuses',
    AGENT_CHANGE_STATUS = 'agent/changestatus',
    GET_PLAYER_ALL = 'player/getall',
    GET_PLAYER_BY_ID = 'player/getbyid',
    PLAYER_GET_STATUSES = 'player/getstatuses',
    ADD_PLAYER = 'player/add',
    EDIT_PLAYER = 'player/update',
    REGISTER_PLAYER = 'player/registerplayer',
    PLAYER_CHANGE_PASSWORD = 'player/changepassword',
    PLAYER_CHANGE_STATUS = 'player/changestatus',
    ACCOUNT_GET_ACCOUNT_TYPES = 'account/getaccounttypes',
    ACCOUNT_GET_TRANSACTION_TYPES = 'account/gettransactiontypes',
    ACCOUNT_PLAYER_TRANSFER = 'account/playertransfer',
    ACCOUNT_AGENT_TRANSFER = 'account/agenttransfer',
    GET_CATEGORIES_BY_PARTNER_ID = 'productcategory/getbypartner',
    UPDATE_PARTNER_CATEGORY = 'productcategory/update',
    ADD_PARTNER_CATEGORY = 'productcategory/add',
    GET_PERMISSION_BY_AGENT_ID = 'permission/getbyagentid',
    GET_AGENT_PERMISSIONS = 'agent/getagentpermissions',
    UPDATE_AGENT_PERMISSION = 'permission/update',
    GET_MESSAGES_STATES_ENUM = 'message/getmessagestatuses',
    ADD_MESSAGE_FOR_PLAYERS = 'message/agent/addmessages',
    GET_PLAYERS_MESSAGES = 'message/agent/getgroupedmessages',
    GET_PLAYER_MESSAGES_BY_MESSAGE_ID = 'message/agent/getchildmessagesbyid',
    REPLY_PLAYER_MESSAGE_BY_MESSAGE_ID = 'message/agent/replymessage',
    GET_PLAYER_DEPOSIT_REQUESTS = 'message/agent/getgroupeddepositrequests',
    GET_PLAYER_WITHDRAW_REQUESTS = 'message/agent/getgroupedwithdrawrequests',
    AGENT_APPROVE_REQUEST = 'message/agent/approverequest',
    AGENT_REJECT_REQUEST = 'message/agent/rejectrequest',
    GET_REQUEST_STATES_ENUM = 'message/getrequeststatuses',
    GET_PLAYERS_NOTIFICATIONS = 'message/agent/getnotifications',
    ADD_NOTIFICATION_FOR_PLAYERS = 'message/agent/addnotifications',
    GET_EARNINGS_BY_AGENT_ID = 'commission/getearninghistory',
    GET_PRODUCT_CATEGORY_TYPES = 'commission/getproductcategory',
    GET_EARNING_TYPES = 'commission/getearningtypes',
    GET_COMMISSION_PERIODS = 'commission/getcommissionperiods',
    GET_COMMISSION_TYPES = 'commission/getcommissiontypes',
    GET_AGENT_COMMISSION = 'commission/get',
    ADD_COMMISSION_PLAN = 'commission/addcommissionplan',
    ADD_COMMISSION = 'commission/addcommission',
    Edit_COMMISSION = 'commission/updatecommission',
    GET_PRODUCT_CATEGORY = 'commission/getproductcategory',
    GET_COMMISSION_HISTORY = 'commission/gethistory',
    GET_PLAYER_BALANCE_TRANSFERS = 'player/gettransactiontree',
    GET_MESSAGES_TYPES_ENUM = 'message/getmessagedirections',
    ACCOUNT_EARNING_TRANSFER = 'account/earningtransfer',
    ACCOUNT_PAYOUT = 'account/payout',
    GET_PLAYER_TRANSACTION = 'player/gettransaction',
    UPDATE_USER_DETAIL = 'user/updatehimself',
    GET_PLAYER_BETS = 'player/getbets',
    GET_PLAYER_BETS_TOTALS = 'player/getbetstotal',
    GET_BETS_REPORT = 'report/getbets',
    GET_BETS_REPORT_TOTALS = 'report/getbetstotal',
    GET_BET_DETAIL = 'report/getbetinfobyid',
    GET_BET_STATES_ENUM = 'report/getbetstatuses',
    GET_CATEGORY_BETS_REPORT = 'report/getcategorybets',
    GET_PLAYERS_BETS_REPORT = 'report/getproductbetsbyplayer',
    GET_PRODUCT_BETS_REPORT = 'report/getproductbets',
    GET_PARTNER_PRODUCTS_STATES_ENUM = 'product/getstatuses',
    GET_PARTNER_PRODUCTS = 'product/getall',
    ADD_PARTNER_PRODUCT = 'product/add',
    EDIT_PARTNER_PRODUCT = 'product/update',
    HEALTH_CHECK = 'health/healthz',
    GET_AGENT_TREE_BY_PARENT_ID = 'agent/getbyparentid',
    GET_LANGUAGES_ENUM = 'translation/getlanguages',
    GET_TRANSLATIONS = 'translation/gettranslationsbyparentid',
    ADD_TRANSLATION = 'translation/add',
    EDIT_TRANSLATION = 'translation/updatetranslation',
    REMOVE_TRANSLATION = 'translation/removetranslation',
    UPDATE_TRANSLATION_JSON = 'translation/uploadjsonfiles',
    ADD_LANGUAGE = 'translation/addlanguage',
    EDIT_LANGUAGE = 'translation/updatelanguage',
    REMOVE_LANGUAGE = 'translation/removelanguage',
    LOGOUT = 'authentication/logout',
    GET_USER_STATES_ENUM = 'user/getusertypes',
    GET_ADMIN_USERS = 'user/getall',
    CREATE_ADMIN_USER = 'user/add',
    ADMIN_USER_CHANGE_STATUS = 'user/changestatus',
    GET_ADMIN_USER_BY_ID = 'user/getbyid',
    ADMIN_USER_CHANGE_PASSWORD = 'user/changepassword',
    UPDATE_ADMIN_USER_DETAIL = 'user/update',
    GET_PERMISSIONS_BY_ADMIN_USER_ID = 'permissionuser/getbyuserid',
    ADD_PERMISSION_TO_ADMIN_USER = 'permissionuser/add',
    REMOVE_PERMISSION_FROM_ADMIN_USER = 'permissionuser/removepermissionbyuserid',
    GET_PARTNERS_BY_ADMIN_USER_ID = 'user/getusertopartnerbyuserid',
    ADD_PARTNER_TO_ADMIN_USER = 'user/addpartnertouser',
    REMOVE_PARTNER_FROM_ADMIN_USER = 'user/removepartnerfromuser',
    GET_USER_TRANSACTION = 'user/gettransaction',
    GET_EARNINGS_REPORT = 'report/getearnings',
    GET_DATE_TIME_FILTER_TYPES_ENUM = 'report/getdatetimefiltertypes',
    GET_AGENT_BY_USER_NAME = 'agent/findbyusername',
    ADD_AGENT_NOTE = 'note/add',
    UPDATE_AGENT_NOTE = 'note/update',
    UPDATE_ADMIN_PERMISSION = 'agent/updatePermission',
    GET_LOGS = 'userlog/getuserlogs',
    GET_GENERATED_PLAYER = 'player/generateplayeranddeposit',
    MOVE_PLAYER = 'player/moveplayer',
    GET_AGENT_TREE_GGR = 'report/getagenttreeggr',
    GET_PARTNER_CONFIG = 'partnerkey/get',
    UPDATE_PARTNER_CONFIG = 'partnerkey/save',
    ACCOUNT_RETRY_PAYMENT = 'account/retrypayment',
    GET_TRANSACTIONS_STATUSES = 'player/gettransactionstatuses',
    CHECK_TOKEN = 'authentication/checktoken',
    AGENT_MONEY_TRANSFER = 'agent/moneytransfer',
    GET_PLAYER_BY_USER_NAME = 'player/findbyusername',
    PLAYER_MONEY_TRANSFER = 'player/moneytransfer',
    MOVE_AGENT = 'agent/moveagent',
}
