import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from '@core/components';

import { AppNotfoundComponent } from '@pages/notfound/app.notfound.component';
import { UnAuthGuard, UserTypeGuard } from '@core/guards';
import { AuthGuard } from '@core/guards/auth.guard';
import { AppMaintenanceComponent } from '@pages/maintenance';
import { PermissionsGuard } from '@core/guards/permissions.guard';
import { AccessDeniedComponent } from '@pages/acces-denied';
import permissions from '@permissions/permissions.json';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                canActivateChild: [AuthGuard],
                children: [
                    // {
                    //     path: '',
                    //     data: {
                    //         breadcrumb: {
                    //             title: 'Dashboard',
                    //             navigate: true,
                    //         }
                    //
                    //     },
                    //     component: DashboardDemoComponent
                    // },
                    {
                        path: '',
                        redirectTo: 'agents',
                        pathMatch: 'full'
                    },
                    {
                        path: 'agents',
                        loadChildren: () => import('@pages/agents/agents.module').then(m => m.AgentsModule),
                        data: { permissions: permissions.AgentPermissions.ViewAgent },
                        canLoad: [PermissionsGuard],
                        canActivate: [PermissionsGuard],
                    },
                    {
                        path: 'players',
                        loadChildren: () => import('./pages/players/players.module').then(m => m.PlayersModule),
                        data: { permissions: permissions.PlayerPermissions.ViewPlayer },
                        canLoad: [PermissionsGuard],
                    },
                    {
                        path: 'logs',
                        loadChildren: () => import('./pages/logs/logs.module').then(m => m.LogsModule),
                        data: { userTypes: ['admin'], permissions: permissions.LogsPermissions.ViewLogsPermissions },
                        canLoad: [UserTypeGuard, PermissionsGuard],
                    },
                    {
                        path: 'users',
                        loadChildren: () => import('@pages/admin-users/admin-users.module').then(m => m.AdminUsersModule),
                        data: { userTypes: ['admin'], permissions: permissions.AdminUserPermissions.ViewAdminUser },
                        canLoad: [UserTypeGuard, PermissionsGuard],
                    },
                    {
                        path: 'player-requests',
                        loadChildren: () => import('./pages/player-requests/player-requests.module').then(m => m.PlayerRequestsModule),
                    },
                    {
                        path: 'partners',
                        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule),
                        data: { userTypes: ['admin'], permissions: permissions.PartnerPermissions.ViewPartner },
                        canLoad: [UserTypeGuard, PermissionsGuard],
                    },
                    {
                        path: 'messaging-center',
                        loadChildren: () => import('@pages/messaging-center/messaging-center.module').then(m => m.MessagingCenterModule),
                        data: { userTypes: ['agent'] },
                        canLoad: [UserTypeGuard],
                    },
                    {
                        path: 'agent-transactions',
                        loadChildren: () => import('@pages/agent-transactions/agent-transactions.module')
                            .then(m => m.AgentTransactionsModule),
                    },
                    {
                        path: 'user-transactions',
                        loadChildren: () => import('@pages/user-transactions/user-transactions.module')
                            .then(m => m.UserTransactionsModule),
                        data: { userTypes: ['admin'], permissions: permissions.TransactionsPermissions.ViewUserTransactions },
                        canLoad: [UserTypeGuard, PermissionsGuard],
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('@pages/reports/reports.module').then(m => m.ReportsModule),
                    },
                    {
                        path: 'my-profile',
                        loadChildren: () => import('@pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
                    },
                    {
                        path: 'cms',
                        loadChildren: () => import('@pages/cms/cms.module').then(m => m.CmsModule),
                        data: { userTypes: ['admin'] },
                        canLoad: [UserTypeGuard],
                    },
                    { path: 'access-denied', component: AccessDeniedComponent },
                ]
            },
            {
                path: 'register-player',
                loadChildren: () => import('@pages/register-player/register-player.module')
                    .then(m => m.RegisterPlayerModule)
            },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'under-maintenance', component: AppMaintenanceComponent },

            {
                path: 'login',
                canActivate: [UnAuthGuard],
                loadChildren: () => import('@pages/auth/auth.module').then(m => m.AuthModule)
            },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
