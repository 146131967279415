import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IAgentPermissions } from '@core/interfaces';

@Component({
  selector: 'app-permission-edit-info',
  templateUrl: './permission-edit-info.component.html',
  styleUrls: ['./permission-edit-info.component.scss']
})
export class PermissionEditInfoComponent {
    @Input() agentPermission: IAgentPermissions;
    @Input() isDesktopView: boolean;
    @Input() permissionManagementForm: FormGroup;

    public pending: boolean = false;

    constructor() { }
}
