<div class="inner-page-main-section">
    <form [formGroup]="permissionManagementForm">


        <div class="p-fluid p-formgrid grid form">
            <div class="col-12 field-section">
                <div class="section-title-name">{{'PermissionList' | translate | uppercase}}</div>
            </div>
            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="AgentAdd">{{ 'CreateAgent' | translate }}</label>
                        <p-checkbox binary="false"
                                    name="AgentAdd"
                                    id="AgentAdd"
                                    formControlName="AgentAdd"
                        ></p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="AgentBlock">{{ 'BlockAgent' | translate }}</label>
                        <p-checkbox binary="false"
                                    name="AgentBlock"
                                    id="AgentBlock"
                                    formControlName="AgentBlock"
                        ></p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="AgentChangePassword">
                            {{ 'ChangeAgentPassword' | translate }}
                        </label>
                        <p-checkbox binary="false"
                                    name="AgentChangePassword"
                                    id="AgentChangePassword"
                                    formControlName="AgentChangePassword"
                        ></p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="DepositToAgent">{{ 'DepositToAgent' | translate }}</label>
                        <p-checkbox binary="false"
                                    name="DepositToAgent"
                                    id="DepositToAgent"
                                    formControlName="DepositToAgent">
                        </p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="WithdrawalFromAgent">{{ 'WithdrawalFromAgent' | translate }}</label>
                        <p-checkbox binary="false"
                                    name="WithdrawalFromAgent"
                                    id="WithdrawalFromAgent"
                                    formControlName="WithdrawalFromAgent">
                        </p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="AgentIndirectTransfer">{{ 'AgentIndirectTransfer' | translate }}</label>
                        <p-checkbox binary="false"
                                    name="AgentIndirectTransfer"
                                    id="AgentIndirectTransfer"
                                    formControlName="AgentIndirectTransfer">
                        </p-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="PlayerAdd">{{ 'CreatePlayer' | translate }}</label>
                        <p-checkbox binary="false"
                                    name="PlayerAdd"
                                    id="PlayerAdd"
                                    formControlName="PlayerAdd">
                        </p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="PlayerBlock">{{ 'BlockPlayer' | translate }}</label>
                        <p-checkbox binary="false"
                                    name="PlayerBlock"
                                    id="PlayerBlock"
                                    formControlName="PlayerBlock"
                        ></p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="PlayerChangePassword">
                            {{ 'ChangePlayerPassword' | translate }}
                        </label>
                        <p-checkbox binary="false"
                                    name="PlayerChangePassword"
                                    id="PlayerChangePassword"
                                    formControlName="PlayerChangePassword"
                        ></p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="DepositToPlayer">
                            {{ 'DepositToPlayer' | translate }}
                        </label>
                        <p-checkbox binary="false"
                                    name="DepositToPlayer"
                                    id="DepositToPlayer"
                                    formControlName="DepositToPlayer">
                        </p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="withdrawalFromPlayer">
                            {{ 'WithdrawalFromPlayer' | translate }}
                        </label>
                        <p-checkbox binary="false"
                                    name="WithdrawalFromPlayer"
                                    id="WithdrawalFromPlayer"
                                    formControlName="WithdrawalFromPlayer">
                        </p-checkbox>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name" for="PlayerIndirectTransfer">
                            {{ 'PlayerIndirectTransfer' | translate }}
                        </label>
                        <p-checkbox binary="false"
                                    name="PlayerIndirectTransfer"
                                    id="PlayerIndirectTransfer"
                                    formControlName="PlayerIndirectTransfer">
                        </p-checkbox>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>
