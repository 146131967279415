import * as menuItems from '../menu-items/agent-menu';

export const environment = {
    ApiUrl: 'https://pre-api.playerms.com/api/agentsystem/',
    AGGridLicence: 'CompanyName=CG Soft LLC,LicensedApplication=CGS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026402,ExpiryDate=17_March_2023_[v2]_MTY3OTAxMTIwMDAwMA==dd30e37fd5c9e3f4f9954b9b6d22b38e',
    role: 'agent',
    cdnUrl: 'https://cdn.playerms.com/',
    production: true,
    menuItems: menuItems.AgentMenu
};
