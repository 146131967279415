import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionsService } from '@core/services';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})

export class PermissionsGuard implements CanLoad, CanActivate {

    private isAdmin: boolean = environment.role === 'admin';

    constructor(
        private router: Router,
        private permissionsService: PermissionsService
    ) {
    }

    canLoad(route: Route, segments: UrlSegment[]):
        boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.checkPermissions(route);
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkPermissions(route);
    }

    private checkPermissions(route: Route | ActivatedRouteSnapshot): Observable<boolean> | boolean {
        if (this.isAdmin) {
            return this.permissionsService.userPermissions$
                .pipe(
                    map(perms => {
                        if (this.permissionsService.filteredAdminMenuItems.length) {
                            const hasPermission: boolean = route.data.permissions
                                .every(permission => perms.hasOwnProperty(permission));
                            if (hasPermission) {
                                return true;
                            } else {
                                if ( this.permissionsService.filteredAdminMenuItems[0].routerLink ) {
                                    this.router.navigate(this.permissionsService.filteredAdminMenuItems[0].routerLink);
                                } else {
                                    this.router.navigate(this.permissionsService.filteredAdminMenuItems[0].items[0].routerLink);
                                }
                                return false;
                            }
                        } else {
                            this.router.navigate(['/access-denied']);
                            return false;
                        }
                    })
                );
        } else {
            return true;
        }
    }
}
