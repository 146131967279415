import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { BreadcrumbService, PermissionsService } from '@core/services';
import { AdminUsersService } from '@pages/admin-users';
import { IAdminUser } from '@core/interfaces';

@Component({
    selector: 'app-admin-user',
    templateUrl: './admin-user.component.html',
    styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit, OnDestroy {

    public tabMenuItems: MenuItem[];
    public activeItem: MenuItem;
    public adminUserDetail: IAdminUser;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private translateService: TranslateService,
        private adminUsersService: AdminUsersService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.setTabMenuItems();
        this.subscribeToLangChanges();
        this.getAdminUserDetail();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public setTabMenuItems(): void {
        this.tabMenuItems = [
            {
                label: this.translateService.instant('PersonalDetails'),
                routerLink: 'personal-details'
            },
            {
                label: this.translateService.instant('Permissions'),
                routerLink: 'permissions',
                visible: !!this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AdminUserPermissions?.ViewAdminUserPermissions)
            },
            {
                label: this.translateService.instant('Partners'),
                routerLink: 'partners',
                visible: !!this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AdminUserPermissions?.ViewAdminUserPartners)
            },
            {
                label: this.translateService.instant('UserTransactions'),
                routerLink: 'user-transactions',
                visible: !!this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.TransactionsPermissions?.ViewUserTransactions)
            },
        ];

        this.activeItem = this.tabMenuItems[0];
    }

    private subscribeToLangChanges(): void {
        this.translateService.onLangChange
            .pipe(take(1))
            .subscribe(() => this.setTabMenuItems());
    }

    private getAdminUserDetail(): void {
        this.adminUsersService.getAdminUserById(+this.route.snapshot.params.id);
        this.adminUsersService.adminUserInfo$
            .pipe(
                filter(res => !!res),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: res => {
                    this.adminUserDetail = res;
                    this.route.snapshot.data.breadcrumb.title = res.UserName;
                    this.breadcrumbService.setBreadcrumb(this.router.routerState.snapshot.root, [], []);
                }
            });
    }

}
