import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class UserTypeGuard implements CanLoad, CanActivate {

    constructor(private router: Router) {
    }

    canLoad(route: Route, segments: UrlSegment[]):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkUserType(route);

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkUserType(route);
    }

    private checkUserType(route): boolean {
        const types: string[] = route.data.userTypes as Array<string>;
        if (types.indexOf(environment.role) === -1) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
