import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';


import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { CommonService, ToastService } from '@core/services';
import { AdminUsersService } from '@pages/admin-users';
import { passwordsMatch } from '@core/validators';
import { IAdminUserForm } from '@core/interfaces';
import { RegexesEnum } from '@core/enums';

@Component({
    selector: 'app-create-admin-user',
    templateUrl: './create-admin-user.component.html',
    styleUrls: ['./create-admin-user.component.scss']
})
export class CreateAdminUserComponent implements OnInit {

    public createAdminUserForm = new FormGroup<IAdminUserForm>({
        UserName: new FormControl('', [
            Validators.required,
            Validators.pattern(RegexesEnum.NamePatternRegex),
            Validators.maxLength(50)
        ]),
        FirstName: new FormControl('', [
            Validators.pattern(RegexesEnum.NamePatternRegex),
            Validators.maxLength(50)
        ]),
        LastName: new FormControl('', [
            Validators.pattern(RegexesEnum.NamePatternRegex),
            Validators.maxLength(50)
        ]),
        Currency: new FormControl('', Validators.required),
        Password: new FormControl('', [
            Validators.required,
            Validators.maxLength(50)
        ]),
        ConfirmPassword: new FormControl('', [
            Validators.required,
            Validators.maxLength(50)
        ]),
        PhoneNumber: new FormControl('', [
            Validators.maxLength(50)
        ]),
        TimeZone: new FormControl(new Date().getTimezoneOffset() / -60, [
            Validators.maxLength(50)
        ]),
    }, { validators: [passwordsMatch('Password', 'ConfirmPassword')] });
    public pending: boolean = false;


    constructor(
        public commonService: CommonService,
        public ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private router: Router,
        private adminUsersService: AdminUsersService,
        private toastService: ToastService,
        private translateService: TranslateService,
    ) {
        this.subscribeToRouterEvents();
    }

    ngOnInit(): void {
    }

    public onUpdateTimeZone(event): void {
        this.createAdminUserForm.controls.TimeZone.setValue(event);
    }

    public onCancel(): void {
        this.ref.close();
    }

    public onCreateAdminUser(): void {
        if (this.createAdminUserForm.valid) {
            this.pending = true;
            this.adminUsersService.createAdminUser(this.createAdminUserForm.value)
                .pipe(take(1))
                .subscribe({
                    next: res => {
                        this.toastService.showToastMsg('success', 'CreationSuccessMessage', 'User');
                        this.ref.close(res);
                        this.pending = false;
                    },
                    error: err => {
                        this.toastService.showToastMsg('error', err?.error?.message);
                        this.pending = false;
                    }
                });
        } else {
            this.createAdminUserForm.markAllAsTouched();
        }
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.ref.close();
            });
    }
}
