<ng-container *ngIf="!agentPermission; else agentPermissionDetailBlock">
    <div class="spinner-container">
        <i class="pi pi-spin pi-spinner ml-2"></i>
    </div>
</ng-container>
<ng-template #agentPermissionDetailBlock>
    <div class="inner-page-main-section">

        <div class="p-fluid p-formgrid grid form">
            <div class="col-12 field-section">
                <div class="section-title-name">{{'PermissionList' | translate | uppercase}}</div>
            </div>
            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field ">
                        <label class="field-name">{{ 'CreateAgent' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.AgentAdd"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">{{ 'BlockAgent' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.AgentBlock"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">
                            {{ 'ChangeAgentPassword' | translate }}
                        </label>
                        <app-boolean-transform
                            [boolean]="agentPermission.AgentChangePassword"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">{{ 'DepositToAgent' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.DepositToAgent"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">{{ 'WithdrawalFromAgent' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.WithdrawalFromAgent"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">{{ 'AgentIndirectTransfer' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.AgentIndirectTransfer"
                        ></app-boolean-transform>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field editable-field">
                        <label class="field-name">{{ 'CreatePlayer' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.PlayerAdd"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">{{ 'BlockPlayer' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.PlayerBlock"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">
                            {{ 'ChangePlayerPassword' | translate }}
                        </label>
                        <app-boolean-transform
                            [boolean]="agentPermission.PlayerChangePassword"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">
                            {{ 'DepositToPlayer' | translate }}
                        </label>
                        <app-boolean-transform
                            [boolean]="agentPermission.DepositToPlayer"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">
                            {{ 'WithdrawalFromPlayer' | translate }}
                        </label>
                        <app-boolean-transform
                            [boolean]="agentPermission.WithdrawalFromPlayer"
                        ></app-boolean-transform>
                    </div>
                </div>
                <div>
                    <div class="field editable-field">
                        <label class="field-name">{{ 'PlayerIndirectTransfer' | translate }}</label>
                        <app-boolean-transform
                            [boolean]="agentPermission.PlayerIndirectTransfer"
                        ></app-boolean-transform>
                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-template>
