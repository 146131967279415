import { Component, Input } from '@angular/core';

import { IAgentPermissions } from '@core/interfaces';

@Component({
  selector: 'app-permission-info',
  templateUrl: './permission-info.component.html',
  styleUrls: ['./permission-info.component.scss']
})
export class PermissionInfoComponent {
    @Input() agentPermission: IAgentPermissions;
    @Input() isDesktopView: boolean;
    constructor() { }
}
