<div class="confirmation-text">{{config.data.text}}</div>

<div class="p-dialog-footer">
    <button
        type="button"
        pButton
        pRipple
        [label]="'Cancel' | translate"
        (click)="onCancel()"
        icon="pi pi-times"
        class="p-button-text p-button-secondary"
    ></button>
    <button
        type="button"
        pButton
        pRipple
        [loading]="pending"
        [label]="'Save' | translate"
        (click)="onSave()"
        icon="pi pi-check"
        class="p-button-text"
    ></button>
</div>
